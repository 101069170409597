
<template>
<form autocomplete="off" class="">
    <is-loading v-if="isLoading" :box="true" />
    <div class="row gx-lg-4 g-3">  
        <div class="col-md-6">
            <label for="tag" class="form-label">Choose tag</label>
            <select v-model="data.tag" name="tag" id="tag" class="form-select">
            <option disabled value="" >--choose tag--</option>
                <option v-for="(tag, sIndex) in tagOptions" :key="sIndex" 
                :value="tag.value">{{tag.label}}</option>
            </select>
        </div>
        <div class="col-md-6">
            <label class="form-label">Assign Date</label>
            <flat-pickr
                v-model="data.created_between"
                :config="rangeConfig"
                placeholder="Select date"
                class="form-control flatpickr-input"
            ></flat-pickr>
        </div>
    </div>
    <div class="row mt-3 justify-content-end">
        <div class="col-12">
            <div class="mt-3 text-end">
            <b-button variant="primary" class="me-3"
                @click.prevent="getList()"
                type="button"> Get List </b-button>

            <b-button variant="dark" 
                @click.prevent="resetForm()"
                type="button"> Reset </b-button>
            </div>
        </div>
    </div>
</form>
</template>
    
<script>

import IsLoading from '@/components/IsLoading.vue'
import flatPickr from "vue-flatpickr-component";

export default {
    components: {
        IsLoading,
        flatPickr
    },
    data() {
        return {
            isLoading: false,
            data: {
                created_between: "",
                tag: ""
            },
            rangeConfig: {
                mode: "range",
                dateFormat: "Y-m-d",
                altInput: true,
                showMonths: 2,
                altFormat: "F j, Y",
                // minDate: "today",
                maxDate: new Date().fp_incr(1)
            },
            mirrorData: null,
            tagOptions:[
                { value: "Normal", label: "Normal" },
                { value: "Urgent", label: "Urgent" }
            ],
        }
    },
    methods: {
        getList() {
            var payload = Object.fromEntries(
                Object.entries(this.data).filter(([_, v]) => !!v )
            );
            this.$emit('startSearch', payload)
        },
        resetForm() {
            this.data = {...this.mirrorData}
            this.$emit('resetFilters')
        },
    },
    mounted(){
        this.mirrorData = JSON.parse(JSON.stringify(this.data))
    },
}
</script>
